import React, { useCallback, useEffect, useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Section } from '../section';
import { BrandLogo } from '../brand-logo';

const Header: React.FC<{ sections?: Section[] }> = ({ sections }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const location = useLocation();

  const [isTransparent, setIsTransparent] = useState(true);

  const handleScroll = useCallback((e) => {
    const window = e.currentTarget;
    setIsTransparent(window.scrollY == 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const updateLocation = (hash: string) => {
    location.hash = hash;
  };

  return (
    <>
      <header className={isTransparent ? 'transparent fixed-top' : 'fixed-top'}>
        <Navbar expand={'md'} container>
          <NavbarBrand
            to={'#intro'}
            tag={Link}
          >
            <BrandLogo />
          </NavbarBrand>
          {sections != undefined && (
            <>
              <NavbarToggler className="navbar-toggler" onClick={toggle}>
                <div
                  className={isOpen ? 'animated-icon open' : 'animated-icon'}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </NavbarToggler>
              <Collapse navbar isOpen={isOpen}>
                <Nav
                  className="d-flex flex-wrap justify-content-end w-100"
                  navbar
                >
                  {sections.map((section: Section) => {
                    if (!section.isShownInNav) return null;

                    return (
                      <NavItem key={section.id}>
                        <NavLink
                          to={`#${section.id}`}
                          tag={Link}
                        >
                          {section.name}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Collapse>
            </>
          )}
        </Navbar>
      </header>
    </>
  );
};

export default Header;
