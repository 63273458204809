import React from 'react';
import { Footer } from './footer';
import Header from './header';
import { Section } from '../section';

const Layout: React.FC<{ sections?: Section[] }> = ({ sections, children }) => {
  return (
    <>
      <Header sections={sections} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
