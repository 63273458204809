import React from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <Container>{t('footer.copyright')}</Container>
      </footer>
    </>
  );
};
