import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next';
import { Section } from '../components/section';
import Layout from '../components/layout';
import '../styles/app.scss';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import ContactForm from '../components/contact-form';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const IntroSection: Section = {
    name: t('intro.title'),
    id: 'intro',
    isShownInNav: false,
    content: (
      <>
        <Container>
          <Row>
            <div className={'wrapper'}>
              <div className={'brand'} />
              {/*<h1>{t('name')}</h1>*/}
              <span className={'description'}>{t('intro.description')}</span>
              <span className={'soon'}>{t('intro.coming-soon')}</span>
              <div className={'items upper'}>
                <a
                  href={'https://store.steampowered.com/app/2078200/Exer_Gale/'}
                  target={'_blank'}>
                  <div className={'logo logo-steam'} />
                </a>
                <a href={'https://www.meta.com/experiences/8008532059164602/'}
                   target={'_blank'}>
                  <div className={'logo logo-meta-horizon'} />
                </a>
              </div>
              <div className={'items lower'}>
                {/*<Link to={'#contacts'}>*/}
                {/*  <PrimaryButton>{t('intro.connect-with-us')}</PrimaryButton>*/}
                {/*</Link>*/}
                <Link to={'https://discord.gg/hNVMRYdBNd'} target={'_blank'}>
                  <SecondaryButton>{t('intro.join-community')}</SecondaryButton>
                </Link>
                <Link
                  to={'https://drive.google.com/drive/folders/1W_k6__YX68TxSeVq13snGTYO5B78VB6V'}
                  target={'_blank'} className='d-none d-md-block'>
                  <PrimaryButton>{t('intro.press-kit')}</PrimaryButton>
                </Link>
              </div>
              <span className={'scroll'}>
                <div className={'icon icon-scroll'} />
              </span>
            </div>
          </Row>
        </Container>
        <div className={'overlay'} />
        <div className={'museum'} />
      </>
    ),
  };

  const AboutSection: Section = {
    name: t('about.title'),
    id: 'about',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <Col lg={5} md={6} sm={12} className={'description'}>
              <h1>{t('about.title')}</h1>
              <p>{t('about.description')}</p>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <img src={'/images/about/player.png'} className={'img-fluid'}
                   alt='Player' />
            </Col>
          </Row>
        </Container>
      </>
    ),
  };

  const ContactsSection: Section = {
    name: t('contacts.title'),
    id: 'contacts',
    isShownInNav: true,
    content: (
      <>
        <Container>
          <Row>
            <Col lg={7} md={6} sm={12}>
              <img src={'/images/contacts/watch_tower.png'}
                   className={'img-fluid'} alt='Watch tower' />
            </Col>
            <Col lg={5} md={6} sm={12}>
              <h2>{t('contacts.connect-with-us')}</h2>
              <div className={'contacts-items'}>
                {/*<a>*/}
                {/*  <div className={'icon icon-steam'} />*/}
                {/*</a>*/}
                <a href={'https://www.youtube.com/@exergale'} target={'_blank'}>
                  <div className={'icon icon-youtube'} />
                </a>
                <a href={'https://x.com/exergale'} target={'_blank'}>
                  <div className={'icon icon-twitter'} />
                </a>
                <a
                  href={'https://www.instagram.com/exergale/'}
                  target={'_blank'}
                >
                  <div className={'icon icon-instagram'} />
                </a>
                <a href={'https://www.facebook.com/exergale'} target={'_blank'}>
                  <div className={'icon icon-facebook'} />
                </a>
                <a href={'https://www.tiktok.com/@exergale'} target={'_blank'}>
                  <div className={'icon icon-tiktok'} />
                </a>
                <a href={'https://discord.gg/hNVMRYdBNd'} target={'_blank'}>
                  <div className={'icon icon-discord'} />
                </a>
              </div>
              <h2>{t('contacts.lets-talk')}</h2>
              <div className={'contact-form'}>
                <ContactForm />
              </div>
            </Col>

          </Row>
        </Container>
      </>
    ),
  };

  const sections: Section[] = [
    IntroSection,
    AboutSection,
    ContactsSection,
  ];

  return (
    <Layout sections={sections}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{`${t('name')} - ${t('slogan')}`}</title>
        <meta name={'description'} content={t('description')} />
        <meta name={'keywords'} content={t('keywords')} />
        <meta
          name={'viewport'}
          content={'width=device-width, initial-scale=1.0'}
        />
        <meta property={'og:title'} content={`${t('name')} - ${t('slogan')}`} />
        <meta property={'og:description'} content={t('description')} />
        <meta property={'og:type'} content={'website'} />
        <meta property={'og:url'} content={location.origin} />
        <meta
          property={'og:image'}
          content={`https://exergale.com/images/meta.png`}
        />
        <meta property={'og:image:height'} content={'500'} />
        <meta property={'og:image:width'} content={'500'} />
        {/*<script src={'https://js.hcaptcha.com/1/api.js'} async defer />*/}
      </Helmet>
      {sections.map((section: Section) => {
        if (section.id != 'features' && section.id != 'contacts')
          return (
            <section id={section.id} key={section.id}>
              {section.content}
            </section>
          );
      })}
      <div className={'bottom'}>
        {sections.map((section: Section) => {
          if (section.id == 'features' || section.id == 'contacts')
            return (
              <section id={section.id} key={section.id}>
                {section.content}
              </section>
            );
        })}
      </div>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
