import React, { useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { SecondaryButton } from '../buttons';

interface FormData {
  subject: string;
  accessKey: string;
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const [formData, setFormData] = useState<FormData>({
    subject: 'Get in Touch - Exer Gale',
    accessKey: '220cad50-fb07-421a-9fb2-d814eb328e2a',
    name: '',
    email: '',
    message: '',
  });

  // const [token, setToken] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (token != '') {
    await axios.post('https://api.staticforms.xyz/submit', {
      ...formData,
    });
    await navigate('/success');
    // }
  };

  const handleMessageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.style.height = 'inherit';
    target.style.height = `${target.scrollHeight}px`;
    setFormData({ ...formData, message: e.target.value });
  };

  const isFormComplete = () => {
    return (
      formData.name != '' && formData.email != '' && formData.message != ''
    );
  };

  return (
    <>
      <Form inline onSubmit={handleSubmit} method={'post'}>
        <FormGroup floating>
          <Input
            placeholder={`${t('contacts.name')}`}
            id={'name'}
            name={'name'}
            type={'text'}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <Label for={'name'}>{t('contacts.name')}</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            placeholder={`${t('contacts.email')}`}
            id={'email'}
            name={'email'}
            type={'email'}
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
          />
          <Label for={'email'}>{t('contacts.email')}</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            placeholder={`${t('contacts.message')}`}
            id={'message'}
            name={'message'}
            type={'textarea'}
            onChange={handleMessageOnChange}
          />
          <Label for={'message'}>{t('contacts.message')}</Label>
        </FormGroup>
        {/*<FormGroup>*/}
        {/*  <HCaptcha*/}
        {/*    sitekey={'35547cc8-14e2-4410-bae8-91c5fc0c43a9'}*/}
        {/*    onVerify={setToken}*/}
        {/*  />*/}
        {/*</FormGroup>*/}
        <SecondaryButton disabled={!isFormComplete()}>
          {t('contacts.submit')}
        </SecondaryButton>
      </Form>
    </>
  );
};

export default ContactForm;
