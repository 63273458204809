import React from 'react';

const BrandLogo: React.FC = () => {
  return (
    <svg viewBox="0 0 500 80" fill='#FCFCFC' xmlns="http://www.w3.org/2000/svg"
         className="brand-logo">
      <title>Exer Gale</title>
      <path
        d="M416.62 28.666L405.183 40.0706L416.62 51.4752L428.052 40.0706L416.62 28.666Z" />
      <path
        d="M342.532 0L328.845 79.985H377.564L363.984 0H342.532ZM358.988 64.1698H347.442L353.239 30.3792L358.988 64.1698Z" />
      <path
        d="M119.666 16.3432C119.666 22.017 116.568 27.2467 111.583 29.9891H111.58C106.592 27.2437 103.494 22.014 103.494 16.3432V0H87.3213V16.1572C87.3213 25.2365 91.2471 33.9227 98.0999 39.9895C91.2501 46.0593 87.3213 54.7455 87.3213 63.8248V79.985H103.494V63.6357C103.494 57.962 106.592 52.7322 111.58 49.9899C116.568 52.7352 119.666 57.965 119.666 63.6357V79.985H135.836V63.8248C135.836 54.7455 131.91 46.0593 125.06 39.9895C131.91 33.9227 135.836 25.2365 135.836 16.1572V0H119.666V16.3432Z" />
      <path
        d="M200.291 79.9758H216.461V48.4085H217.026C225.633 48.4085 232.633 55.3905 232.633 63.9716V79.9758H248.803V63.8726C248.803 54.8114 244.865 46.1642 238 40.1484C244.865 34.0215 248.803 25.2663 248.803 16.1331V0.00585938H200.291V79.9758ZM232.633 16.1361V16.4841C232.633 25.1853 225.537 32.2633 216.809 32.2633H216.461V16.1361H232.633Z" />
      <path
        d="M397.096 0H380.927V47.5715C380.927 65.481 395.484 80 413.44 80H429.441V63.8728H413.377C404.388 63.8728 397.099 56.6028 397.099 47.6375V0H397.096Z" />
      <path
        d="M30.8379 48.0575C30.8379 65.6579 45.1964 79.9789 62.843 79.9789H79.3524V63.8486H62.5482C53.9806 63.8486 47.0104 56.8967 47.0104 48.3515V48.0575H63.1829V31.9272H47.0104V16.1361H79.3524V0.00585938H30.8379V48.0575Z" />
      <path
        d="M143.808 48.0575C143.808 65.6579 158.166 79.9789 175.813 79.9789H192.322V63.8486H175.518C166.95 63.8486 159.98 56.8967 159.98 48.3515V48.0575H176.153V31.9272H159.98V16.1361H192.322V0.00585938H143.808V48.0575Z" />
      <path
        d="M451.991 48.3576V48.0635H468.163V31.9363H451.991V16.1451H484.333V0.00292969H435.818V48.0665C435.818 65.67 450.177 79.9879 467.823 79.9879H484.333V63.8577H467.529C458.961 63.8577 451.991 56.9058 451.991 48.3576Z" />
      <path
        d="M272.357 46.6921C272.357 65.0486 287.333 79.9846 305.737 79.9846H320.869V31.381H304.696V63.8574C295.78 63.8574 288.527 56.6504 288.527 47.7902V16.1449H320.869V0.0146484H272.357V46.6921Z" />
      <path
        d="M488.568 28.666L477.131 40.0706L488.568 51.4752L500 40.0706L488.568 28.666Z" />
      <path
        d="M11.4345 28.666L0 40.0706L11.4345 51.4752L22.8689 40.0706L11.4345 28.666Z" />
    </svg>
  );
};
export default BrandLogo;
